<template>
  <div id="cheeseVideoContainer" className="title-animation cheese-video-container" data-end="200%">
    <div id="cheeseVideoId" className="title-animation--footer">
      <img v-if="!isPreloadLottie" className="title-animation--footer-preload" src="/video/cheese-preload.jpg" />
    </div>

    <div id="cheeseSwa" className="title-animation-swa cheese-swa">
      <div id="cheeseSwaText" className="title-animation-swa__testo--text cheese-swa--text">
        <svg width="260" height="200" viewBox="0 0 260 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.96 198.424V16.876H55.35V30.16H36.244V185.14H55.35V198.424H22.96Z"/>
          <path d="M127.745 165.296C121.896 165.296 116.456 164.23 111.427 162.098C106.398 159.911 102.052 156.85 98.389 152.914C94.781 148.923 92.1297 144.195 90.435 138.728L104.457 134.71C106.261 140.177 109.268 144.331 113.477 147.174C117.686 150.017 122.415 151.411 127.663 151.356C132.474 151.247 136.628 150.153 140.127 148.076C143.68 145.944 146.414 143.047 148.327 139.384C150.295 135.721 151.279 131.485 151.279 126.674C151.279 119.349 149.092 113.417 144.719 108.88C140.346 104.288 134.606 101.992 127.499 101.992C125.531 101.992 123.454 102.265 121.267 102.812C119.135 103.359 117.14 104.124 115.281 105.108L108.229 93.546L152.017 53.94L153.903 58.86H95.109V44.92H164.727V58.942L127.417 94.694L127.253 89.528C135.07 88.9813 141.904 90.266 147.753 93.382C153.602 96.498 158.14 100.953 161.365 106.748C164.645 112.543 166.285 119.185 166.285 126.674C166.285 134.218 164.59 140.887 161.201 146.682C157.812 152.477 153.22 157.041 147.425 160.376C141.63 163.656 135.07 165.296 127.745 165.296Z"/>
          <path d="M237.104 198.424H204.714V185.14H223.82V30.16H204.714V16.876H237.104V198.424Z"/>
        </svg>
        <span>выбираем идеальный сыр </span>
      </div>
    </div>
  </div>
  <div className="content third" data-speed="auto">
    <div className="content-block">
      <div className="content-block__left border">
        <div className="content-block__left-top">
          <div className="content-block__left-top--title">
            настоящая моцарелла
          </div>
          <div className="content-block__left-top--desc">
            В нашей моцарелле нет растительных жиров, а&nbsp;только ингредиенты из&nbsp;отборного молока. Поставщик проверяет каждую партию сыра, а&nbsp;мы выбираем случайные упаковки и&nbsp;тестируем их в&nbsp;лаборатории. Всё это помогает пицце быть такой, как задумано.
          </div>
        </div>
        <div className="content-block__left-bot">
          <img
            className="content-block__left-bot--img"
            src="/img/+.svg"
          />
          <div className="content-block__left-bot--text">
            на этикетке указаны реальные значения БЖУ
          </div>
        </div>
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Тестируем моцареллу <br class="br__desk"/>и&nbsp;проверяем всё&nbsp;—&nbsp;от&nbsp;тягучести до&nbsp;микробиологии
        </div>        
        <div id="rightImg" className="content-block__right--img">
          <img :src="`/img/pizza/pizza-1${isMobile ? '-m' : ''}.png`" className="content-block__right--img__png"/>
          <img :src="`/img/pizza/pizza-1${isMobile ? '-m': ''}.svg`" className="content-block__right--img__svg"/>
        </div>
        <div className="content-block__right--description">
              <div className="content-block__right--description__left">*</div>
              <div className="content-block__right--description__right">
                этикетки продуктов содержат примерные значения <br />
                БЖУ на 100 г. в соответствии со средними значениями
                определёнными всемирной организацией здоровья
              </div>
          </div>
      </div>
    </div>

    <div className="content-block">
      <div className="content-block__left anim-step-by-step anim-step-left">
        <div className="content-block__left-info">
          <div className="content-block__left-info--text">
            В составе — ингредиенты только из&nbsp; коровьего молока. Не&nbsp;используем заменители молочного белка
          </div>
          <div className="content-block__left-info--imgs">
            <img src="/img/pizza/info-1.svg" />
            <img src="/img/pizza/info-2.svg" />
          </div>
        </div>
      </div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right-ingredients third-block__right-ingredients">
          <div className="third-block__right-ingredients-ingredient">
            <img className="third-block__right-ingredients-ingredient--img" src="/img/pizza/block-info-1.svg" />
            <div className="third-block__right-ingredients-ingredient__right">
              <div className="third-block__right-ingredients-ingredient--title">
                запекаемость
              </div>
              <div className="third-block__right-ingredients-ingredient--desc">
                сыр не горит в&nbsp;конвейерной печи&nbsp;при&nbsp;температуре 265°С
              </div>
            </div>
          </div>
          <div className="third-block__right-ingredients-ingredient">
              <img className="third-block__right-ingredients-ingredient--img" src="/img/pizza/block-info-2.svg" />
              <div className="third-block__right-ingredients-ingredient__right">
                <div className="third-block__right-ingredients-ingredient--title">
                  вкус
                </div>
                <div className="third-block__right-ingredients-ingredient--desc">
                  нейтральный и&nbsp;сливочный, не&nbsp;перебивает другие ингредиенты
                </div>
              </div>
              
            </div>
            <div className="third-block__right-ingredients-ingredient">
              <img className="third-block__right-ingredients-ingredient--img" src="/img/pizza/block-info-3.svg" />
              <div className="third-block__right-ingredients-ingredient__right">
                <div className="third-block__right-ingredients-ingredient--title">
                  текстура
                </div>
                <div className="third-block__right-ingredients-ingredient--desc">
                  сыр легко жевать, он&nbsp;не&nbsp;скрипит на зубах и&nbsp;хорошо тянется
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div className="content-block third">
      <div className="content-block__left anim-step-by-step anim-step-left">
        <img
          className="content-block__left--elem"
          src="/img/pizza/3.1.svg"
        />
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Измеряем даже <br class="br__mob" />длину нитей, <br className="br__desk" />которые вытягиваются из&nbsp;расплавленного сыра
        </div>
      </div>
    </div>

    <div className="content-block fourth">
      <div
        className="content-block__left fourth anim-step-by-step anim-step-left"
      >
        <div className="content-block__left-info">
          <div className="content-block__left-info--text">
            температура тестирования сыра на&nbsp;целевой внешний вид
          </div>
          <div className="content-block__left-info--imgs">
            <img src="/img/pizza/265.svg" />
          </div>
        </div>
      </div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="third-block__right--custom">
          <div className="third-block__right--custom--left">
            <div className="third-block__right--custom--left__item">
              <img src="/img/pizza/h.svg" />
              <span>30-35 см</span>
            </div>
            <div className="third-block__right--custom--left__item">
                <img src="/img/pizza/t.svg" />
                <span>2  минуты</span>
            </div>
            <div className="third-block__right--custom--left__desc">
              От того, как именно тянется сыр, зависит ваш неповторимый опыт, когда вы заказываете пиццу Додо. Сырные нити должны быть эластичными и&nbsp;мягкими
            </div>
          </div>
          <img className="third-block__right--custom--img" :src="`/img/pizza/pizza-2${isMobile ? '-m' : ''}.png`" />
          <div className="third-block__right--custom--right">
            <img src="/img/pizza/clock.svg" />
            <span>t — время, в течение которого мы проверяем сыр </span>
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['isPreloadLottie'],
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },
  },
  mounted() {

  },
  unmounted() {

  },
};
</script>
