<template>
    <Preloader :isMobile="isMobile" :percentLoading="percentLoading" :isFinishLoaded="isFinishLoaded" :changeLoadedStatus="changeLoadedStatus" />
    <MainView v-if="!isLoaded" :lottieTomatoAnimation="lottieTomatoAnimation" :setFinishLoaded="setFinishLoaded"/>
</template>

<script>
import { loadingResouces } from '@/scripts/resouces.js';
import Preloader from './Preloader/Preloader.vue';
import MainView from './MainView/MainView.vue';
import ArticlesView from './ArticlesView.vue';


export default {
    data() {
    return {
        isLoaded: true,
        isFinishLoaded: false,
        percentLoading: 0,
        isMobile: false,
        idArticle: 0,
        lottieTomatoAnimation: null
    };
},
components: { Preloader, MainView, ArticlesView },
methods: {
    setFinishLoaded(e){
        this.isFinishLoaded = e;
    },
    changeContext(e) {
        this.$store.commit('changeMediaArray', e);
    },
    changeLoadedStatus(e) {
        this.isLoaded = e;        
    },
    updatePercent(e) {
        this.percentLoading = e;
    },
    checkMobile() {
        if (window.innerWidth < 900) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
        this.$store.commit('setIsMobile', this.isMobile);
    },
    async loadLottieAnimation() {
        try {
            const lottieModule = await import('@/assets/lottie/animations/korobka.json');
            this.lottieTomatoAnimation = lottieModule.default;
        } catch (error) {
            console.error('Error loading Lottie JSON:', error);
        }
    }
},
created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
},
mounted() {
    const checkWidthPage = window.innerWidth;
    let isTypeMob = false;

    if (checkWidthPage < 900) {
        isTypeMob = true;
        this.loadLottieAnimation();
    }

    let dataImages = isTypeMob ? [
        {
                path: '/img/',
                name: 'testo-m',
                type: '.jpg',
                typeMedia: 'image',
            },
            {
                path: '/img/fourth/',
                name: 'test-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/video/',
                name: 'tomato-preload',
                type: '.jpg',
                typeMedia: 'image',
            },
            {
                path: '/video/',
                name: 'cheese-preload',
                type: '.jpg',
                typeMedia: 'image',
            },
            {
                path: '/img/tomato/',
                name: 'tomato-1-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: '1-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/pizza/',
                name: 'pizza-1-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/pizza/',
                name: 'pizza-2-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/video/',
                name: 'korobka-end-m',
                type: '.jpg',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'dough-up',
                type: '.jpg',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: '2',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/tomato/',
                name: 'bottle-teh',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/tomato/',
                name: 'bottle-pro',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: '1-m',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'appstore',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'googlplay',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'appgallery',
                type: '.png',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'logo',
                type: '.svg',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'logo-txt',
                type: '.svg',
                typeMedia: 'image',
            },
            {
                path: '/img/',
                name: 'items-m',
                type: '.svg',
                typeMedia: 'image',
            },
    ] : [
        {
            path: '/video/',
            name: 'korobka',
            type: '',
            typeMedia: 'video',
            count: 104,
        },
        {
            path: '/video/',
            name: 'tomato',
            type: '',
            typeMedia: 'video',
            count: 121,
        },
        {
            path: '/video/',
            name: 'cheese',
            type: '',
            typeMedia: 'video',
            count: 120,
        },
        {
            path: '/img/',
            name: 'testo',
            type: '.jpg',
            typeMedia: 'image',
        },
        {
            path: '/img/fourth/',
            name: 'test',
            type: '.png',
            typeMedia: 'image',
        },
        {
                path: '/video/',
                name: 'korobka-end',
                type: '.jpg',
                typeMedia: 'image',
            },
        {
            path: '/img/tomato/',
            name: 'tomato-1',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/pizza/',
            name: 'pizza-1',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/pizza/',
            name: 'pizza-2',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: 'dough-up',
            type: '.jpg',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: '2',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/tomato/',
            name: 'bottle-teh',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/tomato/',
            name: 'bottle-pro',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: '1',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/video/ingredients/',
            name: 'bluecheese',
            type: '.gif',
            typeMedia: 'image',
        },
        {
            path: '/video/ingredients/',
            name: 'kolbasa',
            type: '.gif',
            typeMedia: 'image',
        },
        {
            path: '/video/ingredients/',
            name: 'halfmush',
            type: '.gif',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: 'appstore',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: 'googlplay',
            type: '.png',
            typeMedia: 'image',
        },
        {
            path: '/img/',
            name: 'appgallery',
            type: '.png',
            typeMedia: 'image',
        },
    ];

    let mediaArray = [];

    dataImages.forEach((e, index) => {
        mediaArray.push({ arraySrc: [], type: e.typeMedia, name: e.name });
        if (e.typeMedia == 'video') {
            mediaArray[index].arraySrc.push([{ url: `${e.path + e.name + e.type}.mp4`, type: 'video/mp4', count: e.count }]); //, 
        } else {
            if (e.range) {
                for (let i = e.range[0]; i <= e.range[1]; i++) {
                    mediaArray[index].arraySrc.push(
                        `${e.path}${e.name}${e.extraName ? e.extraName : ''}${i.toString().padStart(5, '0')}${e.type}`
                    );
                }
            } else {
                mediaArray[index].arraySrc.push(
                    `${e.path}${e.name}${e.type}`
                );
            }
        }
    });

    loadingResouces(mediaArray, this.changeContext, this.updatePercent); 
    window.scrollTo(0,1);   
},
beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
},
};

</script>