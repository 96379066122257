<template>  
  <div id="tomatoContainer" className="title-animation tomato--container" >
    <div id="tomatoAnimation" className="title-animation--footer" >  
      <img v-if="!isPreloadLottie" className="title-animation--footer-preload" src="/video/tomato-preload.jpg" />
    </div>

    <div id="tomatoSwa" className="title-animation-swa tomato-swa">
      <div id="tomatoSwaText" className="title-animation-swa__testo--text tomato--text ">
        <svg width="260" height="200" viewBox="0 0 260 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.96 198.424V16.876H55.35V30.16H36.244V185.14H55.35V198.424H22.96Z" />
          <path d="M90.7938 162.918L90.8758 149.798L143.274 102.648C147.647 98.712 150.572 94.9947 152.048 91.496C153.578 87.9427 154.344 84.3073 154.344 80.59C154.344 76.0527 153.305 71.9527 151.228 68.29C149.15 64.6273 146.308 61.73 142.7 59.598C139.146 57.466 135.101 56.4 130.564 56.4C125.862 56.4 121.68 57.5207 118.018 59.762C114.355 61.9487 111.458 64.8733 109.326 68.536C107.248 72.1987 106.237 76.1893 106.292 80.508H91.3678C91.3678 73.0733 93.0898 66.5133 96.5338 60.828C99.9778 55.088 104.652 50.6053 110.556 47.38C116.514 44.1 123.266 42.46 130.81 42.46C138.135 42.46 144.695 44.1547 150.49 47.544C156.284 50.8787 160.849 55.4433 164.184 61.238C167.573 66.978 169.268 73.4833 169.268 80.754C169.268 85.8927 168.612 90.348 167.3 94.12C166.042 97.892 164.074 101.391 161.396 104.616C158.717 107.787 155.355 111.176 151.31 114.784L107.768 153.898L105.882 148.978H169.268V162.918H90.7938Z"/>
          <path d="M237.104 198.424H204.714V185.14H223.82V30.16H204.714V16.876H237.104V198.424Z"/>
        </svg>
        <span>готовим<br />томатный<br />соус</span>
      </div>
    </div>
  </div>

  <div className="content" data-speed="auto">
    <div className="content-block">
      <div className="content-block__left border">
        <div className="content-block__left-top">
          <div className="content-block__left-top--title">
            cоус, созданный учеными
          </div>
          <div className="content-block__left-top--desc">
            Мы поняли, что ни один из готовых соусов не&nbsp;даст нашей пицце нужный
            вкус, и&nbsp;решили изобрести свой: привлекли НИИ и&nbsp;скрестили тонкое
            чутье наших шефов со&nbsp;сложными приборами
          </div>
        </div>
        <div className="content-block__left-bot">
          <img
            className="content-block__left-bot--img"
            src="/img/+.svg"
          />
          <div className="content-block__left-bot--text">
            на этикетке указаны реальные значения БЖУ готового пицца-соуса
          </div>
        </div>
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Исследуем томаты в&nbsp;лаборатории, 
          чтобы найти баланс вкуса 
          на&nbsp;основе&nbsp;химического состава
        </div>
        <div id="rightImg" className="content-block__right--img">
          <img :src="`/img/tomato/tomato-1${isMobile ? '-m' : ''}.png`" className="content-block__right--img__png"/>
          <img :src="`/img/tomato/tomato-1${isMobile ? '-m' : ''}.svg`" className="content-block__right--img__svg"/>
        </div>
        <div className="content-block__right--description">
            <div className="content-block__right--description__left">*</div>
            <div className="content-block__right--description__right">
              этикетки продуктов содержат примерные значения <br />
              БЖУ на 100 г. в соответствии со средними значениями
              определёнными всемирной организацией здоровья
            </div>
        </div>
      </div>
    </div>

    <div className="content-block">
      <div className="content-block__left "></div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right-elements">
          <div className="content-block__right-elements--element">
            <img
              className="content-block__right-elements--element__img"
              src="/img/tomato/element-1.svg"
            />
            <div className="content-block__right-elements--element__txt">
              Измеряем уровень кислотности
            </div>
          </div>
          <div className="content-block__right-elements--element">
            <img
              className="content-block__right-elements--element__img"
              src="/img/tomato/element-2.svg"
            />
            <div className="content-block__right-elements--element__txt">
              Определяем сколько воды в плодах
            </div>
          </div>
          <div className="content-block__right-elements--element">
            <img
              className="content-block__right-elements--element__img"
              src="/img/tomato/element-3.svg"
            />
            <div className="content-block__right-elements--element__txt">
              Проверяем количество сахаров в мякоти
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="content-block third">
      <div className="content-block__left anim-step-by-step anim-step-left">
        <img
          className="content-block__left--elem"
          src="/img/tomato/2.1.svg"
        />
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Создаем соус из томатной<br className="br__desk" /> основы&nbsp;по нашему фирменному <br className="br__desk" />рецепту:
        </div>
      </div>
    </div>

    <div className="content-block fourth">
      <div
        className="content-block__left fourth anim-step-by-step anim-step-left"
      >
        <div className="content-block__left-info">
          <div className="content-block__left-info--text">
            Используем только натуральные консерванты
          </div>
          <div className="content-block__left-info--imgs">
            <img src="/img/tomato/info-1.svg" />
            <img src="/img/tomato/info-2.svg" />
          </div>
        </div>
      </div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="tomato--container--bottle">
          <div className="tomato--container--bottle__left">
            <div className="tomato--container--bottle__left-top">
              <div className="tomato--container--bottle__left-top--item">
                <input type="radio" for="technik" value="teh" name="inputRadio" v-model="picked">
                <span>технология</span>                
              </div>
              <div className="tomato--container--bottle__left-top--item second--bottle">
                  <input type="radio" for="technik" value="pro" name="inputRadio" v-model="picked">
                  <span>состав</span>
                </div>
            </div>
            <div v-if="picked === 'teh'" className="tomato--container--bottle__left-bot">
              Для идеальной консистенции добавляем немного воды и пектина — это натуральное вещество, которое содержится в клетках многих растений. Томатную основу сдабриваем солью и лимонным соком. Они раскрывают вкус и продлевают свежесть.
            </div>
            <div v-else className="tomato--container--bottle__left-wrap">
                <div className="tomato--container--bottle__left-wrap--item">
                  <div className="tomato--container--bottle__left-wrap--item__title">основа:</div>
                  <div className="tomato--container--bottle__left-wrap--item__elements">
                    <span>измельченные томаты</span>
                  </div>
                </div>
                <div className="tomato--container--bottle__left-wrap--item">
                    <div className="tomato--container--bottle__left-wrap--item__title">текстура:</div>
                    <div className="tomato--container--bottle__left-wrap--item__elements">
                      <span>вода</span><span>пектин</span>
                    </div>
                </div>
                <div className="tomato--container--bottle__left-wrap--item">
                      <div className="tomato--container--bottle__left-wrap--item__title">КОНСЕРВАНТЫ:</div>
                      <div className="tomato--container--bottle__left-wrap--item__elements">
                        <span>СОЛЬ</span><span>сок лимона</span>
                      </div>
                </div>
                <div className="tomato--container--bottle__left-wrap--item">
                        <div className="tomato--container--bottle__left-wrap--item__title">аромат:</div>
                        <div className="tomato--container--bottle__left-wrap--item__elements">
                          <span>базилик</span><span>орегано</span><span>тимьян</span><span>чеснок</span>
                        </div>
                </div>
            </div>
          </div>
          <img v-show="picked === 'teh'" className="tomato--container--bottle__right" :src="`/img/tomato/bottle-teh.png`" />
          <img v-show="picked !== 'teh'" className="tomato--container--bottle__right" :src="`/img/tomato/bottle-pro.png`" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['isPreloadLottie'],
  data(){
    return {
      picked: 'teh'
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },
  },
  methods: {
   
  },
  mounted() {
    
  },
  unmounted() {

  },
};
</script>
