<template>   
  <div id='scrollAnimation'>
    <my-custom-first-component key="first--component" />
    <my-custom-second-component key="second--component" :isPreloadLottie="isLoadedTomato" />
    <my-custom-third-component key="third--component" :isPreloadLottie="isLoadedCheese" />
    <my-custom-fourth-component key="fourth--component" />  
    <my-custom-fifth-component key="fifth--component" />   
  </div>
</template>

<script>
import gsap from 'gsap';
import lottie from 'lottie-web'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

import FirstComponent from '@/components/First/First.vue';
import SecondComponent from '@/components/Second/Second.vue';
import ThirdComponent from '@/components/Third/Third.vue';
import FourthComponent from '@/components/Fourth/Fourth.vue';
import FifthComponent from '@/components/Fifth/Fifth.vue';

import canAutoPlay from 'can-autoplay';

let ctx;

export default {
  name: 'MainView',
  props: ['lottieTomatoAnimation', 'setFinishLoaded'],
  components: {
    'my-custom-first-component' : FirstComponent,
    'my-custom-second-component': SecondComponent,
    'my-custom-third-component': ThirdComponent,
    'my-custom-fourth-component': FourthComponent,
    'my-custom-fifth-component': FifthComponent
  },
  data() {
    return {
      isLoadedTomato: false,
      isLoadedCheese: false
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },   
  },
  methods: {
    mountedDesk: function() {
      const isVideo = this.mediaArray.isVideo;
      //LET FIRST MEDIA 
      const firstMedia = this.mediaArray.videos['korobka'];
      let firstCanvas = document.createElement('canvas');;
      let firstCtxCanvas = firstCanvas.getContext('2d');

      //LET SECOND MEDIA
      const secondMedia = this.mediaArray.videos['tomato'];
      let secondCanvas = document.createElement('canvas');      
      let secondCtxCanvas = secondCanvas.getContext('2d');

      //LET THIRD MEDIA
      const thirdMedia = this.mediaArray.videos['cheese'];
      let thirdCanvas = document.createElement('canvas');
      let thirdCtxCanvas = thirdCanvas.getContext('2d');

      // VIDEO ANIMATION   
      firstCanvas.width = secondCanvas.width = thirdCanvas.width = window.innerWidth >= 1920 ? 1920 : window.innerWidth;
      firstCanvas.height = secondCanvas.height = thirdCanvas.height = window.innerWidth >= 1920 ? 1080 : window.innerWidth / 1.7777;
      firstCanvas.classList.add('title-animation--footer-video');
      secondCanvas.classList.add('title-animation--footer-video');
      thirdCanvas.classList.add('title-animation--footer-video');

      document.getElementById('footerRef').appendChild(firstCanvas);
      document.getElementById('tomatoAnimation').appendChild(secondCanvas);     
      document.getElementById('cheeseVideoId').appendChild(thirdCanvas);

      if (isVideo) {
        firstCtxCanvas.drawImage(firstMedia, 0, 0, firstMedia.videoWidth, firstMedia.videoHeight, 0, 0, firstCanvas.width, firstCanvas.height);
        thirdCtxCanvas.drawImage(thirdMedia, 0, 0, thirdMedia.videoWidth, thirdMedia.videoHeight, 0, 0, thirdCanvas.width, thirdCanvas.height);
      } else {
        firstCtxCanvas.drawImage(firstMedia[0], 0, 0, firstMedia[0].width, firstMedia[0].height, 0, 0, firstCanvas.width, firstCanvas.height);
        thirdCtxCanvas.drawImage(thirdMedia[0], 0, 0, thirdMedia[0].width, thirdMedia[0].height, 0, 0, thirdCanvas.width, thirdCanvas.height);
      }
    
      const firstFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 103
      };

      const secondFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 121
      };

      const thirdFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 90
      };
 
      const renderCanvasBox = function (currentFrames, mediaFrames, currentCanvas, ctx) {
        if (isVideo) {
          mediaFrames.addEventListener('seeked', (e) => {
            window.requestAnimationFrame(() => {
              ctx.drawImage(mediaFrames, 0, 0, mediaFrames.videoWidth, mediaFrames.videoHeight, 0, 0, currentCanvas.width, currentCanvas.height)
            })
          })

          window.addEventListener('focus', () => {
            mediaFrames.play().then(() => mediaFrames.pause())
          })

          return function () {
            if (currentFrames.currentFrame !== currentFrames.frame && currentFrames.frame < currentFrames.allFrames) {
              const frameIndex = currentFrames.frame === -1 ? 0 : currentFrames.frame;
              mediaFrames.currentTime = (frameIndex / currentFrames.allFrames) * mediaFrames.duration
            }
            currentFrames.currentFrame = currentFrames.frame;
          }
        } else {
          return function () {
            if (currentFrames.currentFrame !== currentFrames.frame && mediaFrames[currentFrames.frame]?.width) {
              const frameIndex = currentFrames.frame === -1 ? 0 : currentFrames.frame;
              const correctFrame = mediaFrames[frameIndex];
              window.requestAnimationFrame(() => {
                ctx.drawImage(correctFrame, 0, 0, correctFrame.width, correctFrame.height, 0, 0, currentCanvas.width, currentCanvas.height)
              })
            }
            currentFrames.currentFrame = currentFrames.frame;
          }
        }
      }


      ctx = gsap.context((self) => {
        
        


        //FIRST COMPONENT
        const firstTimeLine = gsap
          .timeline({
            scrollTrigger: {
              id: 'mainContainer',
              trigger: document.getElementById('mainContainer'),
              start: 'top top',
              scrub: true,
              pin: true,
              end: '+=400%',
            },
          })

        const firstRenderCanvasBox = renderCanvasBox(firstFrames, firstMedia, firstCanvas, firstCtxCanvas, this.isMobile)

        firstTimeLine.to(firstFrames, {
          frame: firstFrames.allFrames,
          snap: 'frame',
          ease: 'none',
          onUpdate: firstRenderCanvasBox,
          duration: 10
        })

        const testo = document.getElementById('testo');
        const testotext = document.getElementById('testotext');
        const testoup = document.getElementById('testoup');

        firstTimeLine.set(testo, { opacity: 1 })
          .set(testotext, { opacity: 0 })
          .set(firstCanvas, { opacity: 0 })
          .fromTo(
            testoup,
            { clipPath: 'inset(0% 0% 0% 0%)' },
            { clipPath: 'inset(0 0 100% 0)', duration: 5 },
            10
          )                   
          .set(testoup, { opacity: 0 })           
          .to(testo, { borderRadius: '6em', borderWidth: '2em', duration: 3, delay: 2 }, 10)
          .to(testotext, { opacity: 1, duration: 3, delay: 2 }, 10)
          .to(document.getElementById('testoback'), { scale: 1.1, duration: 5, delay: 3 }, 10)   


        // SECOND COMPONENT
        const secondRenderCanvasBox = renderCanvasBox(secondFrames, secondMedia, secondCanvas, secondCtxCanvas);
        gsap.timeline({
          scrollTrigger: {
            id: 'tomatoBackVideoContainer',
            trigger: document.getElementById('tomatoContainer'),
            start: 'top top',
            scrub: true,
            pin: true,
            end: '+=260%',
          },
        })
          .to(secondFrames, {
            frame: secondFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: secondRenderCanvasBox,
            duration: 14
          }, 0)
          .to(document.getElementById('tomatoSwaText'), { color: '#fff', fill: '#fff', duration: 8, delay: 6 }, 0)
          .to(secondCanvas, { borderWidth: '2em', borderRadius: '6em', duration: 5, delay: 14 }, 0)

        // THIRD COMPONENT
        const thirdRenderCanvasBox = renderCanvasBox(thirdFrames, thirdMedia, thirdCanvas, thirdCtxCanvas)
        gsap.timeline({
          scrollTrigger: {
            id: 'cheeseVideoContainer',
            trigger: document.getElementById('cheeseVideoContainer'),
            start: 'top top',
            scrub: true,
            pin: true,
            end: '+=250%',
          },
        })
          .to(thirdFrames, {
            frame: thirdFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: thirdRenderCanvasBox,
            duration: 14
          }, 0)
          .to(thirdCanvas, { borderWidth: '2em', borderRadius: '6em', duration: 5, delay: 14 }, 0)

        // FOURTH COMPONENT
        const fourthImage = document.getElementById('fourthImg');
        const fourtPositionImg = document.getElementById('fourthPositionImg');
        const fourthContainer = document.getElementById('fourthContainer');
        const fourthCarouselContainer = document.getElementById('fourthCarouselContainer');

        const scrollTL = gsap.timeline({
          scrollTrigger: {
            trigger: document.getElementById('fourthBlock'),
            pin: true,
            start: "top top",
            scrub: 1,
            onUpdate: ({ progress }) => {
              if (progress > 0.3 && progress < 1) fourthImage.style.transform = `rotate(${progress * 480}deg)`;
            },
            end: `+=800%`
          }
        });

        scrollTL.to(fourthImage, { marginTop: '-15%', duration: 5 })
        scrollTL.set(fourthContainer, { display: 'none' }, 4)
        scrollTL.to(fourthContainer, { marginTop: '-90%', duration: 1 }, 4)
        scrollTL.to(fourthCarouselContainer, { opacity: 1, duration: 10 }, 5)
        scrollTL.to(fourthImage, {
          width: '35.5%',
          marginTop: `${fourthCarouselContainer.offsetHeight / 2 - (fourthImage.offsetHeight * 0.355) * 0.725}px`,
          marginLeft: `${fourtPositionImg.offsetLeft * 0.962}px`,
          duration: 10
        }, 5)

        let sections = gsap.utils.toArray("#fourthCarouselSections");

        sections.forEach((section, index) => {
          scrollTL.set(section, { opacity: 1 })
            .to(section, {
              opacity: 1, // <-- IMPORTANT!       
              duration: 5,
            }, "+=10")
            .set(section, { opacity: index < 3 ? 0 : 1 });
        })

        // Left and right animation 
        const allLeftBlocks = gsap.utils.toArray('.anim-step-left');
        const allRightBlocks = gsap.utils.toArray('.anim-step-right');

        allLeftBlocks.forEach((element, i) => {
          gsap
            .timeline({
              scrollTrigger: {
                id: 'leftBlock' + i,
                trigger: element,
                start: `top ${this.isMobile ? 65 : 75}%`,
                scrub: true,
                end: '+=25%',
                // once: true,
              },
            })
            .to(element, { opacity: 1 });
        });

        allRightBlocks.forEach((element, i) => {
          gsap
            .timeline({
              scrollTrigger: {
                id: 'rightBlock' + i,
                trigger: element,
                start: 'top 65%',
                scrub: true,
                end: '+=25%',
                // once: true,
              },
            })
            .to(element, { opacity: 1 });
        });

        gsap.utils.toArray('#rightImg').forEach((img, i) => {
          gsap
            .timeline({
              scrollTrigger: {
                id: `imgRight-${i}`,
                trigger: img,
                start: 'top 75%',
                scrub: true,
                end: '+=25%',
              },
            })
            .fromTo(img, { yPercent: 15 }, { yPercent: 0 });
        })

        // TWICE
        const twice = document.getElementById('twice');
        const twiceMore = document.getElementById('twiceMore');
        gsap.timeline({
          scrollTrigger: {
            id: 'twiceMore',
            trigger: twiceMore,
            start: 'top 50%',
            pin: true,
            endTrigger: twice,
            end: () => '+=' + (twice.offsetHeight - twiceMore.offsetHeight) + ' 52%',
          },
        });

        // end CTX
      });
    },
    mountedMobile: function(isEnergySaveMode) {
      
      // VIDEO ANIMATION
     let firstMedia = lottie.loadAnimation({
        container: document.getElementById('footerRef'),
        loop: true,
        autoplay: false,
        animationData: this.lottieTomatoAnimation,        
        rendererSettings: {
          width: window.innerWidth,
          height: window.innerHeight,
          preserveAspectRatio: 'xMidYMid slice',
          className: 'title-animation--footer-video'
        }
      });
      let secondMedia = lottie.loadAnimation({
        container: document.getElementById('tomatoAnimation'),
        loop: true,
        autoplay: false,
        // animationData: tomatoLottie,
        path: '/animations/tomato.json',        
        rendererSettings: {
          width: window.innerWidth,
          height: window.innerHeight,
          preserveAspectRatio: 'xMinYMin slice',
          className: 'title-animation--footer-video'
        }
      });;
      let thirdMedia = lottie.loadAnimation({
        container: document.getElementById('cheeseVideoId'),
        loop: true,
        autoplay: false,
        // animationData: cheeseLottie,
        path: '/animations/cheese.json',
        rendererSettings: {
          width: window.innerWidth,
          height: window.innerHeight,
          preserveAspectRatio: 'xMinYMin slice',
          className: 'title-animation--footer-video'
        }
      });;

      secondMedia.addEventListener('data_ready', () => { this.isLoadedTomato = true});
      thirdMedia.addEventListener('data_ready', () => { this.isLoadedCheese = true });

      const firstFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 104
      };

      const secondFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 120
      };

      const thirdFrames = {
        frame: -1,
        currentFrame: -1,
        allFrames: 90
      };

      const renderCanvasBox = function (currentFrames, currentCanvas) {
          return function () {
            if (currentFrames.currentFrame !== currentFrames.frame && currentFrames.frame > 0 && currentFrames.frame < currentFrames.allFrames) {
              currentCanvas.goToAndStop(currentFrames.frame, true)
            }
            currentFrames.currentFrame = currentFrames.frame;
          }

      }


      ctx = gsap.context((self) => {

        //FIRST COMPONENT
        const testo = document.getElementById('testo');
        const testotext = document.getElementById('testotext');
        const testoback = document.getElementById('testoback');
        const testoup = document.getElementById('testoup');

        const firstRenderCanvasBox = renderCanvasBox(firstFrames, firstMedia)
        const secondRenderCanvasBox = renderCanvasBox(secondFrames, secondMedia);
        const thirdRenderCanvasBox = renderCanvasBox(thirdFrames, thirdMedia)

        if(isEnergySaveMode) {
          const firstOffsetTop = document.getElementById('mainContainer').offsetTop;
          const secondOffsetTop = document.getElementById('tomatoContainer').offsetTop;
          const thirdOffsetTop = document.getElementById('cheeseVideoContainer').offsetTop;

          gsap.timeline({            
            scrollTrigger: {
              id: 'mainContainer',
              trigger: document.getElementById('mainContainer'),
              start: '-1px top',
              once: true,
              onEnter: (self) => {  
                document.documentElement.style.overflowY = 'hidden';
                document.documentElement.style.touchAction = 'none';
                window.scrollTo(0, firstOffsetTop);
              },              
            }
          }).to(firstFrames, {
            frame: firstFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: firstRenderCanvasBox,
            duration: 1.5
          }).set(testo, { opacity: 1 }, 1)
            .set(testotext, { opacity: 0 }, 1)
            .fromTo(
              testoup,
              { clipPath: 'inset(0% 0% 0% 0%)' },
              { clipPath: 'inset(0 0 100% 0)', duration: 1 }, 1
            )
            .set(firstMedia.wrapper, { opacity: 0 })
            .to(testo, { borderRadius: '3em', borderWidth: '1em', duration: 1 }, 2)
            .to(testotext, { opacity: 1, duration: 1 }, 2)
            .to(testoback, { scale: 1.1, duration: 1,
              onComplete: function (self) {
                  document.documentElement.style.overflowY = 'initial'; 
                  document.documentElement.style.touchAction = 'initial';   
                  window.scrollTo({
                    top: firstOffsetTop + 50,
                    behavior: 'smooth'
                  });             
                }, }, 2);

          // second VIDEO
          gsap.timeline({
            scrollTrigger: {
              id: 'tomatoBackVideoContainer--Tomato',
              trigger: document.getElementById('tomatoContainer'),
              start: '-1px top',
              once: true,
              onEnter: (self) => {                
                document.documentElement.style.overflowY = 'hidden';
                document.documentElement.style.touchAction = 'none';
                window.scrollTo(0, secondOffsetTop - 25);
              },
            }
          }).to(secondFrames, {
            frame: secondFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: secondRenderCanvasBox,
            duration: 1.5
          }, 0)
            .to(document.getElementById('tomatoSwaText'), { color: '#fff', fill: '#fff', duration: 1, delay: 0.3 }, 0)
            .to(document.getElementById('tomatoSwa'), { width: '90%', marginLeft: '5%', duration: 0.3, delay: 1 }, 0)
            .to(document.getElementById('tomatoAnimation'), { borderRadius: '3em', borderWidth: '1em', duration: 0.3, delay: 1,
              onComplete: function (self) {
                document.documentElement.style.overflowY = 'initial';   
                document.documentElement.style.touchAction = 'initial';                            
              }, }, 0)

          // third VIDEO
          gsap.timeline({
            scrollTrigger: {
              id: 'cheeseVideoContainer',
              trigger: document.getElementById('cheeseVideoContainer'),
              start: '-1px top',
              once: true,
              onEnter: (self) => {                
                document.documentElement.style.overflowY = 'hidden';
                document.documentElement.style.touchAction = 'none';
                window.scrollTo(0, thirdOffsetTop - 12.5);
              },
            }
          }).to(thirdFrames, {
            frame: thirdFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: thirdRenderCanvasBox,
            duration: 1.5
          }, 0)
            .to(thirdMedia.wrapper, { opacity: 1, duration: 0.5 }, 0)
            .to(document.getElementById('cheeseSwa'), { width: '90%', marginLeft: '5%', duration: 1, delay: 0.5 }, 0)
            .to(document.getElementById('cheeseVideoId'), { borderRadius: '3em', borderWidth: '1em', duration: 1, delay: 0.5, 
              onComplete: function (self) {
                document.documentElement.style.overflowY = 'initial';
                document.documentElement.style.touchAction = 'initial';
              }, }, 0)

        } else {

          
          gsap.timeline({
            scrollTrigger: {
              id: 'mainContainer',
              trigger: document.getElementById('mainContainer'),
              start: 'top top',
              end: '+=2000px',
              scrub: 1,
              pin: true,              
              anticipatePin: 1,
              preventOverlaps: true,
            },
          }).to(firstFrames, {
            frame: firstFrames.allFrames,
            snap: 'frame',
            ease: 'none',
            onUpdate: firstRenderCanvasBox,
            duration: 10
          }).set(testo, { opacity: 1 })
            .set(testotext, { opacity: 0 })
            .fromTo(
              testoup,
              { clipPath: 'inset(0% 0% 0% 0%)' },
              { clipPath: 'inset(0 0 100% 0)', duration: 5 },
              10
            )
            .set(firstMedia.wrapper, { opacity: 0 }, 10)
            .to(testo, { borderRadius: '3em', borderWidth: '1em', duration: 3, delay: 2 }, 10)
            .to(testotext, { opacity: 1, duration: 3, delay: 2 }, 10)
            .to(testoback, { scale: 1.1, duration: 5, delay: 3 }, 10);

            // SECOND COMPONENT
          gsap.timeline({
            scrollTrigger: {
              id: 'tomatoBackVideoContainer--Tomato',
              trigger: document.getElementById('tomatoContainer'),
              start: 'top top',
              end: '+=2000px',
              scrub: 1,
              pin: true,
              anticipatePin: 1,
              preventOverlaps: true,     
            }       
          }).to(secondFrames, {
              frame: secondFrames.allFrames,
              snap: 'frame',
              ease: 'none',
              onUpdate: secondRenderCanvasBox,
              duration: 14
            }, 0)
            .to(document.getElementById('tomatoSwaText'), { color: '#fff', fill: '#fff', duration: 8, delay: 6 }, 0)
            .to(document.getElementById('tomatoSwa'), { width: '90%', marginLeft: '5%', duration: 5, delay: 14 }, 0)
            .to(document.getElementById('tomatoAnimation'), { borderRadius: '3em', borderWidth: '1em', duration: 5, delay: 14 }, 0);
            
       // THIRD COMPONENT
          gsap.timeline({
            scrollTrigger: {              
              id: 'cheeseVideoContainer',
              trigger: document.getElementById('cheeseVideoContainer'),
              start: 'top top',
              scrub: 1,
              pin: true,
              anticipatePin: 1,
              preventOverlaps: true,
              end: '+=200%',
            },
          }).to(thirdFrames, {
              frame: thirdFrames.allFrames,
              snap: 'frame',
              ease: 'none',
              onUpdate: thirdRenderCanvasBox,
              duration: 14
            }, 0)
            .to(thirdMedia.wrapper, { opacity: 1, duration: 2 }, 0)
            .to(document.getElementById('cheeseSwa'), { width: '90%', marginLeft: '5%', duration: 4, delay: 2 }, 0)
            .to(document.getElementById('cheeseVideoId'), { borderRadius: '3em', borderWidth: '1em', duration: 4, delay: 2 }, 0);
   
          }
        

          // FOURTH COMPONENT
        const fourthImage = document.getElementById('fourthImg');
        const fourthContainer = document.getElementById('fourthContainer');
        const fourthCarouselContainer = document.getElementById('fourthCarouselContainer');
        const scrollTL = gsap.timeline({
          scrollTrigger: {
            id: 'fourthVideoContainer',
            trigger: document.getElementById('fourthBlock'),
            pin: true,
            start: "top top",
            scrub: 1,
            onUpdate: ({ progress }) => {
              if (progress > 0.3 && progress < 1) fourthImage.style.transform = `rotate(${progress * 480}deg)`;
            },
            end: `+=800%`
          }
        });

        // - (fourthImage.offsetHeight * 0.51) * 0.66
        scrollTL.to(fourthImage, { top: '-15%', duration: 5 })
        scrollTL.set(fourthContainer, { display: 'none' }, 4)
        scrollTL.to(fourthContainer, { top: '-90%', duration: 1 }, 4)
        scrollTL.to(fourthCarouselContainer, { opacity: 1, duration: 10 }, 5)
        scrollTL.to(fourthImage, {
          width: '51%',
          top: `${(window.innerWidth * 0.6625)}px`,
          left: `${window.innerWidth * 0.075}px`,
          duration: 10
        }, 5)


        let sections = gsap.utils.toArray("#fourthCarouselSections");

        scrollTL.to(fourthContainer, {position:'relative'})

        sections.forEach((section, index) => {
          scrollTL.set(section, { opacity: 1 })
            .to(section, {
              opacity: 1, // <-- IMPORTANT!              
              duration: 5
            },
              "+=10")
            .set(section, { opacity: index < 3 ? 0 : 1 });
        })

        // end CTX
      }, document.getElementById('scrollAnimation'));
    }
  },
  mounted (){

    // ScrollTrigger.defaults({
    //   markers: true,
    // });

    const allImages = document.querySelectorAll('.content img');
      let loadCount = 0;

    ScrollTrigger.addEventListener('refreshInit', () => {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'initial';
    })

      const checkLoaded = () => {
        loadCount++;        
        if (loadCount >= allImages.length) {      
          setTimeout(() => {      
   
          canAutoPlay
            .video({ muted: true, inline: true })
            .then(({ result, error }) => {  
              let isEnergySaveMode = false;      
  
              if (result) {                      
                const app = document.getElementById('app');
                app.addEventListener('touchstart', e => {
                  // console.log('touchStart');
                }, { passive: true });
                app.addEventListener('touchmove', e => {
                  // console.log('touchMove');
                }, { passive: true });

              } else {
                //low power mode
                isEnergySaveMode = true;
              }

              if (this.isMobile) {
                ScrollTrigger.config({ ignoreMobileResize: true });     
                if (!isEnergySaveMode) {
                  ScrollTrigger.normalizeScroll({
                    allowNestedScroll: true,
                    lockAxis: false,
                  });
                }           
                
                this.mountedMobile(isEnergySaveMode);               

              } else {
                this.mountedDesk();
              }
              
            })            

          this.setFinishLoaded(true);
        }, 200)
        }
      }

      allImages.forEach((elem) => {
        elem.addEventListener('load', checkLoaded)
      })

  },
  beforeDestroy(){
    ctx.revert();
  }
};
</script>
