import { createStore } from 'vuex';

export default createStore({
  state: {
    mediaArray: {},
    isMobile: false,
  },
  getters: {},
  mutations: {
    changeMediaArray(state, e) {
      state.mediaArray = e;
    },
    setIsMobile(state, e) {
      state.isMobile = e;
    },
  },
  actions: {},
  modules: {},
});
