import videoDecoder from './videoDecoder.js';

function isWebCodecsSupported() {
  // Check for the presence of the main components of the WebCodecs API
  return (
    'MediaStreamTrackProcessor' in window &&
    'MediaStreamTrackGenerator' in window &&
    'VideoFrame' in window &&
    'AudioData' in window &&
    'VideoDecoder' in window &&
    false
  );
}

async function preloadMediaWithRetries(
  mediaArrays,
  updatePercent,
  setMediaContextObjects
) {
  const maxRetries = 3;
  const result = {
    images: {},
    videos: {},
    singleImages: {},
  };
  let loadedMediaCount = 0;
  let loadedMediaPercent = 0;

  let progressMax = mediaArrays.reduce((sum, array) => {
    if (array.type === 'video') return sum + array.arraySrc[0][0].count;
    return sum + array.arraySrc.length;
  }, 0);

  function updateLoader() {
    const progressPercentage = Math.floor(
      (loadedMediaCount / progressMax) * 100
    );
    if (loadedMediaPercent < progressPercentage) {
      updatePercent(loadedMediaPercent);
      loadedMediaPercent = progressPercentage + 2;
    }
  }

  function preloadVideo(dataVideo, name) {
    return new Promise((resolve, reject) => {
      let videoUrl = dataVideo[0].url;
      const fps = 30;
      let resultArray = [];

      if (isWebCodecsSupported()) {
        console.log('WebCodecs API is supported in this browser.');
        videoDecoder(videoUrl, (frame) => {
          loadedMediaCount++;
          updateLoader();
          resultArray.push(frame);
        }).then(() => {
          loadedMediaCount++;
          updateLoader();
          resolve(resultArray);
        });
        // }
      } else {
        console.log('WebCodecs API is not supported in this browser.');

        const video = document.createElement('video');
        video.preload = 'auto';
        video.setAttribute('autoplay', '');
        video.setAttribute('playsinline', '');
        // video.setAttribute('loop', '');
        video.muted = true;
        video.controls = 'true';
        video.setAttribute('config', '');
        video.onerror = () => reject(`Failed to load video: ${dataVideo}`);
        video.setAttribute('src', dataVideo[0].url);
        video.setAttribute('type', dataVideo[0].type);

        const canvas = document.createElement('canvas');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        let maxLength = 0;

        video.addEventListener('loadeddata', () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          video.removeAttribute('autoplay');
          maxLength = Math.floor(video.duration * fps);
          video.customAllFrames = maxLength;
          video.play().then(() => {
            video.pause();
            video.currentTime = 0;
            loadedMediaCount++;
            updateLoader();
            resolve(video);
          });
        });
      }
    });
  }

  function preloadImage(src, name) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.customName = name;
      img.onload = () => {
        loadedMediaCount++;
        updateLoader(img.customName);
        resolve(img);
      };
      img.onerror = () => reject(`Failed to load image: ${src}`);
      img.src = src;
    });
  }

  for (const media of mediaArrays) {
    for (let retry = 0; retry < maxRetries; retry++) {
      try {
        let loadedMediaItem;

        if (media.type === 'image') {
          loadedMediaItem = await preloadImage(media.arraySrc[0], media.name);
        } else if (media.type === 'video') {
          loadedMediaItem = await preloadVideo(media.arraySrc[0], media.name);
        } else {
          loadedMediaItem = [];
          for (let img of media.arraySrc) {
            const loadedImg = await preloadImage(img);
            loadedMediaItem.push(loadedImg);
          }
        }

        if (media.type === 'video') {
          result.videos = { ...result.videos, [media.name]: loadedMediaItem };
        } else if (media.type === 'image') {
          result.singleImages = {
            ...result.singleImages,
            [media.name]: loadedMediaItem,
          };
        } else {
          result.images = { ...result.images, [media.name]: loadedMediaItem };
        }

        break; // Break out of the retry loop if successful.
      } catch (error) {
        console.error(error);
      }
    }
  }

  setMediaContextObjects({ ...result, isVideo: !isWebCodecsSupported() });
  if (loadedMediaPercent !== 100) updatePercent(100);
}

function loadingResouces(mediaArrays, setMediaContextObjects, updatePercent) {
  preloadMediaWithRetries(mediaArrays, updatePercent, setMediaContextObjects);
}

export { loadingResouces };
