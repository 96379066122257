<template>  
  <div id="fourthBlock" className="fourthBlock" data-end="800%">
    <div id="fourthContainer" className="title-animation title-animation__fourth">
        <div id="fourthSwa" className="title-animation-swa fourth-swa">
          <div id="fourthText" className="title-animation-swa__testo--text fourth-swa--text">
            <svg width="260" height="200" viewBox="0 0 260 200" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.96 198.424V16.876H55.35V30.16H36.244V185.14H55.35V198.424H22.96Z" />
              <path d="M143.636 163V141.27H89.3523V127.412L128.302 44.92H144.866L105.916 127.412H143.636V94.202H158.396V127.412H170.696V141.27H158.396V163H143.636Z"/>
              <path d="M237.104 198.424H204.714V185.14H223.82V30.16H204.714V16.876H237.104V198.424Z" />
            </svg>
            <span>еще раз всё проверяем</span>
          </div>
        </div>        
    </div>

    <div id="fourthImg" className="title-animation--footer title-animation__fourth--img">
              <img className="fourth--test" :src="`/img/fourth/test${isMobile ? '-m': ''}.png`" />
    </div>
    
    <div id="fourthCarouselContainer" className="fourth__container">
      <!-- <Carousel className="fourth__container-carousel" :autoplay="2000" v-bind="settings" :wrap-around="true" :items-to-show="1" > -->
        <!-- <Slide v-for="slide in arrayData" :key="'slide--'+slide.id"> -->
          <div id="fourthCarouselSections" className="fourth__container-carousel__slide" v-for="slide in arrayData" :key="'slide--'+slide.id">
            <div className="fourth__container-carousel__slide-left">
              <div className="fourth__container-carousel__slide-left__rounds">
                <div className="fourth__container-carousel__slide-left__rounds--elem">{{ slide.id }}</div>
                <div className="fourth__container-carousel__slide-left__rounds--elem fourth__container-carousel__slide-left__rounds--down" @click="onScrollDown">
                  <img src="/img/fourth/arrow-down.svg" />
                </div>
              </div>
              <div className="fourth__container-carousel__slide-left-bot">
                <div className="fourth__container-carousel__slide-left__desc" v-html="slide.leftDescription"></div>
                <div classList="fourth__container-carousel__slide-left__icons">
                  <img v-for="icon in slide.icons" :src="`/img/fourth/${icon}`" />
                </div>
              </div>              
            </div>
            <div :id="slide.id === 1 && 'fourthPositionImg'" className="fourth__container-carousel__slide-right">
              <!-- <img className="fourth__container-carousel__slide-right__block" style='opacity:0;' :src="`/img/fourth/${slide.blockImg}${isMobile ? '-m': ''}.png`" /> -->
              <Vue3Lottie className="fourth__container-carousel__slide-right__lottie" :animationData="isMobile ? slide.lottieImgMob : slide.lottieImg" />
              <div className="fourth__container-carousel__slide-right__title" v-html="slide.title"></div>
            </div>
          </div>
        <!-- </Slide> -->

        <!-- <template #addons>
          <Pagination className="fourth__container-carousel__pagination" />
        </template> -->
      <!-- </Carousel> -->
    </div>
  </div>
</template>
<script>

import { Vue3Lottie } from 'vue3-lottie'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

import Final1 from '@/assets/img/lottie/final-1.json'
import Final2 from '@/assets/img/lottie/final-2.json'
import Final3 from '@/assets/img/lottie/final-3.json'
import Final4 from '@/assets/img/lottie/final-4.json'
import Final1Mob from '@/assets/img/lottie/final-1-mob.json'
import Final2Mob from '@/assets/img/lottie/final-2-mob.json'
import Final3Mob from '@/assets/img/lottie/final-3-mob.json'
import Final4Mob from '@/assets/img/lottie/final-4-mob.json'

export default {
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      arrayData: [
        {
          id: 1,
          leftDescription: 'Все ингредиенты соответствуют заданным стандартам',
          icons: ['1.svg', '2.svg'],
          blockImg: 'block-1',
          lottieImg: Final1,
          lottieImgMob: Final1Mob,
          title: 'Заказываем ингредиент так же, как&nbsp;это&nbsp;будут делать менеджеры пиццерий. Затем — готовим пиццу.',
        },
        {
          id: 2,
          leftDescription: 'Собираем десятки <br class="br__mob" />тысяч отзывов',
          icons: ['3.svg', '4.svg', '5.svg'],
          blockImg: 'block-2',
          lottieImg: Final2,
          lottieImgMob: Final2Mob,
          title: 'Опрашиваем гостей <br class="br" />и&nbsp;учитываем их&nbsp;мнение <br class="br" />в&nbsp;работе над&nbsp;рецептами',
        },
        {
          id: 3,
          leftDescription: 'Соблюдаем все стандарты транспортировки пищевых продуктов',
          icons: ['6.svg', '7.svg'],
          blockImg: 'block-3',
          lottieImg: Final3,
          lottieImgMob: Final3Mob,
          title: 'Запускаем новинку в&nbsp;сеть, <br class="br" />если гостям пиццерий <br class="br" />понравился её&nbsp;вкус',
        },
        {
          id: 4,
          leftDescription: 'Продолжаем собирать данные даже после запуска ',
          icons: ['8.svg', '9.svg'],
          blockImg: 'block-4',
          lottieImg: Final4,
          lottieImgMob: Final4Mob,
          title: 'Следим и после запуска: <br class="br" />с помощью тайных проверок, <br class="br" />статистики и рейтинга продукта ',
        },
      ]
    }
  },
  methods: {
    onScrollDown() {
      window.scrollBy({
        top: this.isMobile ? window.innerHeight * 1.5 : 1000,
        behavior: "smooth",
      });
    }
  },  
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
     Vue3Lottie,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },
  },
  mounted() {
  },
  unmounted() {

  },
};
</script>
