import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

let vh = window.innerHeight + 'px';
// if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
//   vh = window.innerHeight + 'px';
// }
document.documentElement.style.setProperty('--vh', vh);

import '@/assets/scss/App.scss';

const app = createApp(App);

// app.use(eventBus);
app.use(router);
app.use(store);
app.mount('#app');
