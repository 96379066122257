<template>
  <div id="preloader2" :class="`preloader ${isLoaded && 'loaded'}`" data-speed="auto">
    <img
      class="preloader--background"
      :src="`/img/gradient${isMobile ? '-m' : ''}.jpg`"
    />
    <div class="preloader-body">
      <a class="preloader-body--logo" href="https://dodopizza.ru" target="_blank">
        <img src="/img/logo-main.svg" />
      </a>      
      <div
        class="preloader-body--title"
        ref="title"
      >
        как мы<br v-if="isMobile" />
        делаем&nbsp;<br v-if="isMobile" />пиццу?
      </div>   
         
      <div ref="indication" class="preloader-body__indication">
        <svg
          class="preloader-body__indication--img"
          width="926"
          height="926"
          viewBox="0 0 926 926"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"                  
        >
          <g mask="url(#circleMask)"   >
            <defs>
              <mask id="circleMask"  maskUnits="userSpaceOnUse">
                <rect x="0" y="0" width="926" height="926" fill="white" />
                <linearGradient id="gradientFill" gradientTransform="rotate(90)">
                  <stop offset="10%" stop-color="white" />
                  <stop
                    :offset="
                      updatePercent > 40
                        ? `${200 - 150 * (updatePercent / 100)}%`
                        : 0
                    "
                    :stop-color="updatePercent > 40 ? 'black' : 'white'"
                  />
                  <stop offset="100%" stop-color="black" />
                </linearGradient>
                <rect
                  x="0"
                  y="0"
                  width="926"
                  height="926"
                  fill="url(#gradientFill)"
                />
              </mask>

              <path
                ref="path"
                d="M 10 463 a 444 444 0 1 1 906 0 a 550 500 0 0 1 -200 370"
                fill="none"
                stroke="transparent"
                vector-effect="non-scaling-stroke"
                />
            </defs> 

            <g ref="circle" transform-box="view-box" stroke="white" stroke-width="8" stroke-dasharray="8 86.2">
                  <circle        
                      opacity="0.6"
                      cx="463px"
                      cy="463px"
                      r="450px"                         
                    />
            </g>
          </g>
        </svg>

        <div ref="percent" class="preloader-body__indication--percent">
          <div :class="`preloader-body__indication--percent--count ${isLoaded && 'percent--loaded'}`">
            <span ref="countingProgress">0 </span>&nbsp;%
          </div>
          <div
            ref="scroll"
            :class="`preloader-body--scroll ${isFinishLoaded && 'show'}`"            
          >
            <img
              class="preloader-body--scroll-img"
              :src="isMobile ? '/img/pointDown.svg' :'/img/scrollDown.svg'"
            />
          </div>
        </div>
      </div>

      <div :class="`preloader-body__bot show`" ref="bot">
        <div class="preloader-body__bot--left">Секреты нашего круга</div>
        <div class="preloader-body__bot--right">2011 — 2024</div>
      </div>

    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

let ctx;
export default {
  props: ['percentLoading', 'isMobile', 'changeLoadedStatus', 'isFinishLoaded'],
  data() {
    return {
      mainTimeLine: null,
      timer: null,
      updatePercent: 0,
      isLoaded: false,
      cacheTween: null,
      afterAnimation: null
    };
  },
  methods: {
    goScroll() {
      document
        .querySelector('#mainContainer')
        .scrollIntoView({ behavior: 'smooth' });
    },
    setUpdatePercent() {
      const percent = this.percentLoading;
      this.updatePercent = percent;

      if (this.mainTimeLine && percent <= 100) {
        this.cacheTween && this.cacheTween.kill();
        this.cacheTween = gsap.to(this.mainTimeLine, percent === 100 ? 1 : 5, {
          duration: 1,
          progress: (percent / 100),
        });
      }      
    },
  },
  watch: {
    percentLoading(e) {
      window.requestAnimationFrame(this.setUpdatePercent);
    },
    isFinishLoaded(e) {
      if(e) this.afterAnimation.pause()
    }
  },
  mounted() {    
    ctx = gsap.context(() => {
        const tl = gsap.timeline({
          repeat: 0,
          paused: true,
          ease: 'none',
          duration: 1, 
          onComplete: () => {
            this.isLoaded = true;
            this.changeLoadedStatus(false);
          }
        });

        if (!this.$props.isMobile) {
          tl.to(
            this.$refs.percent, 1,
            {
              motionPath: {
                path: this.$refs.path,
                align: this.$refs.path,
                alignOrigin: [0.5, 0.5],                
              },
            },
            0
          )
        } 

        tl.to(this.$refs.circle, 1, {
          rotation: "232", transformOrigin: "center",
          onComplete: () => {
            this.afterAnimation = gsap.to(this.$refs.circle, {
              rotation: "360", 
              transformOrigin: "center",
              ease: 'linear',
              duration: 3,
              repeat: -1,
              loop: true
            });
          }
        }, 0)
        .to(this.$refs.title, 1, { scale: 1, ease: this.isMobile ? "ease" : "power4.in", translateY: '0' }, 0)
        .to(this.$refs.bot, 1, {
          scale: 1, marginBottom: 0,
        }, 0)
        .to(this.$refs.countingProgress, 1, {
          innerText: 100,
          snap : {
            innerText: 1
          }
        }, 0)

        this.mainTimeLine = tl;
    });
  },
  unmounted() {
    ctx?.revert();
  },
};
</script>
